import React, { useContext, useState } from "react";
import { Form, Input, Button } from "antd";
import { Redirect } from "react-router-dom";

import { StoreContext } from "../context/context";
import { login } from "../utils/admin";
import notification from "../components/common/notiication";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const Login = () => {
  const [isAuth, setIsAuth] = useState(false);
  const { actions } = useContext(StoreContext);

  const onFinish = (values) => {
    login(values)
      .then((res) => {
        if (res.data && res.data.status) {
          actions.setAuth();
          localStorage.setItem("token", res.data.data.accessToken);
          notification("Login successfully");
          setIsAuth(true);
        }
      })
      .catch((err) => {
        notification(err.message);
      });
  };

  if (isAuth)
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );

  return (
    <Form
      {...layout}
      name="basic"
      style={{ marginTop: "200px" }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
