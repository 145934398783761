import { getAllUser, updateUser } from "../utils/user";
import { getAllPost, updatePost } from "../utils/post";
import notification from "../components/common/notiication";

const types = {
  SET_USERS: "SET_USERS",
  SET_USER: "SET_USER",
  SET_LOADER: "SET_LOADER",
  SET_POSTS: "SET_POSTS",
  SET_POST: "SET_POST",
  SET_AUTH: "SET_AUTH",
  REMOVE_AUTH: "REMOVE_AUTH",
  GET_STARRED_REPO_LIST: "GET_STARRED_REPO_LIST",
  SET_STARRED_REPO_LIST: "SET_STARRED_REPO_LIST",
  GET_SEARCHED_REPO_LIST: "GET_SEARCHED_REPO_LIST",
  SET_SEARCHED_REPO_LIST: "SET_SEARCHED_REPO_LIST",
  CHANGE_COMPONENT: "CHANGE_COMPONENT",
  REMOVE_REPO_BY_ID: "REMOVE_REPO_BY_ID",
  CHANGE_AUTH: "CHANGE_AUTH",
  GET_AUTH: "GET_AUTH",
};

const useActions = (state, dispatch) => {
  const getUserList = async () => {
    dispatch({ type: types.SET_LOADER, payload: true });
    try {
      const res = await getAllUser();
      if (res.data && res.data.data)
        dispatch({ type: types.SET_USERS, payload: res.data.data });
    } catch (err) {
      notification(err.message);
    }
    dispatch({ type: types.SET_LOADER, payload: false });
  };

  const updateSingleUser = async (id, dataToUpdate) => {
    dispatch({ type: types.SET_LOADER, payload: true });
    try {
      const res = await updateUser(id, dataToUpdate);
      if (res.data && res.data.data)
        dispatch({ type: types.SET_USER, payload: res.data.data });
      if (res.data && res.data.message) notification(res.data.message);
    } catch (err) {
      notification(err.message);
    }
    dispatch({ type: types.SET_LOADER, payload: false });
  };

  const updateSinglePost = async (id, dataToUpdate) => {
    dispatch({ type: types.SET_LOADER, payload: true });
    try {
      const res = await updatePost(id, dataToUpdate);
      console.log("updateSinglePost -> res", res);
      if (res.data && res.data.data)
        dispatch({ type: types.SET_POST, payload: res.data.data });
      if (res.data && res.data.message) notification(res.data.message);
    } catch (err) {
      notification(err.message);
    }
    dispatch({ type: types.SET_LOADER, payload: false });
  };

  const getPostList = async () => {
    dispatch({ type: types.SET_LOADER, payload: true });
    try {
      const res = await getAllPost();
      console.log("getPostList -> res.data.data[0]", res.data.data[0])
      if (res.data && res.data.data)
        dispatch({ type: types.SET_POSTS, payload: res.data.data });
    } catch (err) {
      notification(err.message);
    }
    dispatch({ type: types.SET_LOADER, payload: false });
  };

  const setAuth = () => {
    dispatch({ type: types.SET_AUTH });
  };

  const removeAuth = () => {
    dispatch({ type: types.REMOVE_AUTH });
  };

  return {
    getUserList,
    updateSingleUser,
    updateSinglePost,
    getPostList,
    setAuth,
    removeAuth
  };
};

export { useActions, types };
