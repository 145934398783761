import axios from "axios";

import { BASE_URL } from "../config";

export const getAllPost = (queryObj) => {
  /*
   * pagination from back-end side is not implemented yet. So you can ignore "queryString" part
   */

  // let queryString = '';
  // Object.keys(queryObj || {}).forEach(key => queryString += queryString ? `&${key}=${queryObj[key]}` : `?${key}=${queryObj[key]}`)

  return axios.get(`${BASE_URL}admin/post` /* + queryString */, {
    headers: { "Access-Control-Allow-Origin": "*" },
  });
};

export const updatePost = (id, dataToUpdate) => {
  console.log("updateUser -> dataToUpdate", dataToUpdate);
  return axios.put(`${BASE_URL}admin/post/${id}`, {dataToUpdate});
};
