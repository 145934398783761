import { types } from "./actions";

const initialState = {
  users: [],
  posts: [],
  loading: false,
  token: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USERS:
      return { ...state, users: action.payload };
    case types.SET_USER:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) return action.payload;
          else return user;
        }),
      };
    case types.SET_LOADER:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_POSTS:
      return { ...state, posts: action.payload };
    case types.SET_POST:
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id === action.payload.id) return action.payload;
          else return post;
        }),
      };
    case types.SET_AUTH:
      return { ...state, token: true };
    case types.REMOVE_AUTH:
      return { ...state, token: false };
    default:
      return state;
  }
};

export { initialState, reducer };
