import React, { useContext, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";

import { StoreContext } from "../../context/context";

const { Header, Content, Footer } = Layout;

const logout = (removeAuth) => {
  removeAuth();
  localStorage.removeItem("token");
  window.location.href = "/login";
};

export default function HeaderComponent({ children }) {
  const { state, actions } = useContext(StoreContext);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) actions.setAuth();
  }, []);

  return (
    <Layout className="layout">
      <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
          {state.token && (
            <Menu.Item key="1">
              <Link to="/">Home</Link>
            </Menu.Item>
          )}
          {state.token && (
            <Menu.Item key="2">
              <Link to="/user">User</Link>
            </Menu.Item>
          )}
          {state.token && (
            <Menu.Item key="3">
              <Link to="/post">Post</Link>
            </Menu.Item>
          )}
          {state.token && (
            <Menu.Item style={{ float: "right" }} key="4">
              <Link onClick={() => logout(actions.removeAuth)}>logout</Link>
            </Menu.Item>
          )}
          {!state.token && (
            <Menu.Item style={{ float: "right" }} key="4">
              <Link to="/login">Login</Link>
            </Menu.Item>
          )}
        </Menu>
      </Header>
      <Content
        style={{ marginTop: "100px", padding: "0 50px", minHeight: "75vh" }}
      >
        <div className="site-layout-content">{children}</div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Warai ©2018 Created by Warai
      </Footer>
    </Layout>
  );
}
