import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import User from "./pages/users";
import Post from "./pages/post";
import Home from "./pages/home";
import Login from "./pages/login";
import Loader from "./components/common/loader";
import SiderBar from "./components/common/header";

function PrivateRoute({ children, ...rest }) {
  const token = localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default function App() {
  return (
    <Loader>
      <Router>
        <SiderBar>
          <Switch>
            <PrivateRoute exact path="/">
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/user">
              <User />
            </PrivateRoute>
            <PrivateRoute path="/post">
              <Post />
            </PrivateRoute>
            <Route path="/login">
              <Login />
            </Route>
          </Switch>
        </SiderBar>
      </Router>
    </Loader>
  );
}
