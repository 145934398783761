import React, { useState, useEffect, useRef, useContext } from "react";
import { Table, Input, Button, Space, Switch } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { StoreContext } from "../context/context";

function UserList() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchInput = useRef(null);
  const { state, actions } = useContext(StoreContext);

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) => text,
  });

  useEffect(() => {
    actions.getUserList();
  }, []);

  const singleUpdate = (userId, obj) => {
    console.log("singleUpdate -> userId, obj", userId, obj);
    actions.updateSingleUser(userId, obj);
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username"),
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      ...getColumnSearchProps("first_name"),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      ...getColumnSearchProps("last_name"),
    },
    {
      title: "Country",
      dataIndex: "country_id",
      key: "country_id",
      ...getColumnSearchProps("country_id"),
    },
    {
      title: "Block",
      dataIndex: "is_block",
      key: "is_block",
      render(text, record) {
        return (
          <Switch
            checked={record.is_block}
            onChange={(value) => {
              singleUpdate(record.id, { is_block: value });
            }}
          />
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "is_deleted",
      key: "is_deleted",
      render(text, record) {
        return (
          <Switch
            checked={record.is_deleted}
            onChange={(value) => {
              singleUpdate(record.id, { is_deleted: value });
            }}
          />
        );
      },
    },
  ];

  return (
    <Table
      rowSelection={rowSelection}
      bordered
      columns={columns}
      dataSource={state.users}
      rowKey="id"
    />
  );
}

export default UserList;
