import React, { useContext } from "react";
import { Spin } from "antd";

import { StoreContext } from "../../context/context";

export default function Loader({ children }) {
  const { state } = useContext(StoreContext);

  return (
    <Spin tip="Loading..." spinning={state.loading}>
      {children}
    </Spin>
  );
}
